import React, { useEffect, useState, type FC } from 'react';
import { Section, GridTable, useToast } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon, Illustration } from '@theorchard/suite-icons';
import { type AssociatedComposition, formatAssociatedComps } from '../../utils';
import PublisherSearchDropdown from '../publisherSearchDropdown';
import type { WriterAgreement } from 'src/types';

interface Props {
    controlledAgreements: WriterAgreement[];
    nonControlledAgreements: WriterAgreement[];
    labelUuid: string;
}

export const SECTION_CLASSNAME = 'SongWriterEditModal-associatedComps';

const AssociatedCompositions: FC<Props> = ({
    controlledAgreements,
    nonControlledAgreements,
    labelUuid,
}) => {
    const [controlledComps, setControlledComps] = useState(
        formatAssociatedComps(controlledAgreements)
    );
    const [nonControlledComps, setNonControlledComps] = useState(
        formatAssociatedComps(nonControlledAgreements)
    );
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [selectedControlledRows, setSelectedControlledRows] = useState<
        string[]
    >([]);
    const [selectedNonControlledRows, setSelectedNonControlledRows] = useState<
        string[]
    >([]);
    const [selectedPublisher, setSelectedPublisher] = useState<
        string | undefined
    >(undefined);

    const handleSort = (
        compositions: AssociatedComposition[],
        setCompositions: React.Dispatch<
            React.SetStateAction<AssociatedComposition[]>
        >
    ) => {
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);

        const sortedComps = [...compositions].sort((a, b) => {
            if (newSortDirection === 'asc') {
                return a.title.localeCompare(b.title);
            } else {
                return b.title.localeCompare(a.title);
            }
        });

        setCompositions(sortedComps);
    };

    const toast = useToast();

    useEffect(() => {
        if (selectedPublisher) {
            const filteredControlledComps = formatAssociatedComps(
                controlledAgreements.filter(agreement =>
                    selectedPublisher === '0'
                        ? !agreement.publisher
                        : agreement.publisher?.id === selectedPublisher
                )
            );

            const filteredNonControlledComps = formatAssociatedComps(
                nonControlledAgreements.filter(agreement =>
                    selectedPublisher === '0'
                        ? !agreement.publisher
                        : agreement.publisher?.id === selectedPublisher
                )
            );

            setControlledComps(filteredControlledComps);
            setNonControlledComps(filteredNonControlledComps);
        }

        if (selectedPublisher === undefined) {
            setControlledComps(formatAssociatedComps(controlledAgreements));
            setNonControlledComps(
                formatAssociatedComps(nonControlledAgreements)
            );
        }
    }, [selectedPublisher, controlledAgreements, nonControlledAgreements]);

    return (
        <div className={SECTION_CLASSNAME}>
            <div className={`${SECTION_CLASSNAME}-header`}>
                <h2>{formatMessage('songWriters.associatedCompositions')}</h2>
                <PublisherSearchDropdown
                    labelUuid={labelUuid}
                    onChange={value => setSelectedPublisher(value?.id)}
                    publisherId={selectedPublisher}
                />
            </div>
            <div className={`${SECTION_CLASSNAME}-section`}>
                <div className={`${SECTION_CLASSNAME}-controlled`}>
                    <Section>
                        <Section.Header
                            className={`${SECTION_CLASSNAME}-sectionHeader`}
                            title={formatMessage('generic.controlled')}
                        />
                        <Section.Body>
                            <GridTable
                                variant="zebra"
                                rowKey="id"
                                className={`${SECTION_CLASSNAME}-controlledComps`}
                                data={controlledComps}
                                selectable
                                stickyHeader
                                testId="controlled-compositions"
                                emptyStateComponent={
                                    <div
                                        className={`${SECTION_CLASSNAME}-emptyState`}
                                    >
                                        {!!selectedPublisher &&
                                        !controlledComps.length ? (
                                            <Illustration
                                                name="noResults"
                                                size={100}
                                            />
                                        ) : (
                                            <Illustration
                                                name="emptyState"
                                                size={100}
                                            />
                                        )}
                                        <h4>
                                            {!!selectedPublisher &&
                                            !controlledComps.length
                                                ? formatMessage(
                                                      'songWriterManager.noItems'
                                                  )
                                                : formatMessage(
                                                      'songWriterManager.noCompositions',
                                                      {
                                                          controlledStatus:
                                                              'Controlled',
                                                      }
                                                  )}
                                        </h4>
                                        <div>
                                            {!!selectedPublisher &&
                                            !controlledComps.length
                                                ? formatMessage(
                                                      'songWriterManager.resetFilters'
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                }
                                sortBy={{
                                    key: 'title',
                                    direction: sortDirection,
                                }}
                                onSort={() =>
                                    handleSort(
                                        controlledComps,
                                        setControlledComps
                                    )
                                }
                                columnDefs={[
                                    {
                                        name: 'title',
                                        title: `${formatMessage('songWriterManager.compositions')}`,
                                        sortable: true,
                                        sortKey: 'title',
                                        Cell: ({ data }) => {
                                            return (
                                                <div>
                                                    <div>{data.title}</div>
                                                    <div id="publisher">
                                                        {data.publisherName ??
                                                            ''}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                onSelectedRowsChanged={
                                    setSelectedControlledRows
                                }
                                selectedRowKeys={selectedControlledRows}
                                rowActionsHeader=""
                                rowActions={{
                                    edit: {
                                        icon: (
                                            <GlyphIcon name="edit" size={16} />
                                        ),
                                        tooltip: formatMessage(
                                            'songWriterManager.editPublishingCo'
                                        ),
                                        onClick: row => {
                                            console.log(row);
                                        }, // temporary until onClick handled in future ticket
                                    },
                                    move: {
                                        icon: (
                                            <GlyphIcon
                                                name="arrowRight"
                                                size={16}
                                            />
                                        ),
                                        onClick: () => {
                                            toast(
                                                formatMessage(
                                                    'songWriterManager.compMoved',
                                                    {
                                                        controlledStatus:
                                                            'Non-Controlled',
                                                    }
                                                )
                                            );
                                        },
                                        tooltip: formatMessage(
                                            'songWriterManager.moveComp',
                                            {
                                                controlledStatus:
                                                    'Non-Controlled',
                                            }
                                        ),
                                    },
                                }}
                                rowBulkActions={{
                                    edit: {
                                        icon: (
                                            <GlyphIcon name="edit" size={16} />
                                        ),
                                        tooltip: formatMessage(
                                            'songWriterManager.editPublishingCo'
                                        ),
                                        onClick: () => {
                                            console.log('bulk actions');
                                        }, // temporary until onClick handled in future ticket
                                    },
                                    move: {
                                        icon: (
                                            <GlyphIcon
                                                name="arrowRight"
                                                size={16}
                                            />
                                        ),
                                        onClick: () => {
                                            toast(
                                                formatMessage(
                                                    'songWriterManager.compsMoved',
                                                    {
                                                        number: selectedControlledRows.length,
                                                        controlledStatus:
                                                            'Non-Controlled',
                                                    }
                                                )
                                            );
                                        },
                                        tooltip: formatMessage(
                                            'songWriterManager.moveSelectedComps',
                                            {
                                                controlledStatus:
                                                    'Non-Controlled',
                                            }
                                        ),
                                    },
                                }}
                            />
                        </Section.Body>
                    </Section>
                </div>
                <div className={`${SECTION_CLASSNAME}-nonControlled`}>
                    <Section>
                        <Section.Header
                            className={`${SECTION_CLASSNAME}-sectionHeader`}
                            title={formatMessage('generic.nonControlled')}
                        />
                        <Section.Body>
                            <GridTable
                                variant="zebra"
                                rowKey="id"
                                className={`${SECTION_CLASSNAME}-nonControlledComps`}
                                selectable
                                data={nonControlledComps}
                                stickyHeader
                                testId="non-controlled-compositions"
                                emptyStateComponent={
                                    <div
                                        className={`${SECTION_CLASSNAME}-emptyState`}
                                    >
                                        {!!selectedPublisher &&
                                        !nonControlledComps.length ? (
                                            <Illustration
                                                name="noResults"
                                                size={100}
                                            />
                                        ) : (
                                            <Illustration
                                                name="emptyState"
                                                size={100}
                                            />
                                        )}
                                        <h4>
                                            {!!selectedPublisher &&
                                            !nonControlledComps.length
                                                ? formatMessage(
                                                      'songWriterManager.noItems'
                                                  )
                                                : formatMessage(
                                                      'songWriterManager.noCompositions',
                                                      {
                                                          controlledStatus:
                                                              'Non-Controlled',
                                                      }
                                                  )}
                                        </h4>
                                        <div>
                                            {!!selectedPublisher &&
                                            !nonControlledComps.length
                                                ? formatMessage(
                                                      'songWriterManager.resetFilters'
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                }
                                sortBy={{
                                    key: 'title',
                                    direction: sortDirection,
                                }}
                                onSort={() =>
                                    handleSort(
                                        nonControlledComps,
                                        setNonControlledComps
                                    )
                                }
                                onSelectedRowsChanged={
                                    setSelectedNonControlledRows
                                }
                                selectedRowKeys={selectedNonControlledRows}
                                rowActionsHeader=""
                                rowActions={{
                                    edit: {
                                        icon: (
                                            <GlyphIcon name="edit" size={16} />
                                        ),
                                        tooltip: formatMessage(
                                            'songWriterManager.editPublishingCo'
                                        ),
                                        onClick: row => {
                                            console.log(row);
                                        }, // temporary until onClick handled in future ticket
                                    },
                                    move: {
                                        icon: (
                                            <GlyphIcon
                                                name="arrowLeft"
                                                size={16}
                                            />
                                        ),
                                        onClick: () => {
                                            toast(
                                                `${formatMessage('songWriterManager.compMoved', { controlledStatus: 'Controlled' })}`
                                            );
                                        },
                                        tooltip: formatMessage(
                                            'songWriterManager.moveComp',
                                            { controlledStatus: 'Controlled' }
                                        ),
                                    },
                                }}
                                rowBulkActions={{
                                    edit: {
                                        icon: (
                                            <GlyphIcon name="edit" size={16} />
                                        ),
                                        tooltip: formatMessage(
                                            'songWriterManager.editPublishingCo'
                                        ),
                                        onClick: () => {
                                            console.log('bulk actions');
                                        }, // temporary until onClick handled in future ticket
                                    },
                                    move: {
                                        icon: (
                                            <GlyphIcon
                                                name="arrowLeft"
                                                size={16}
                                            />
                                        ),
                                        onClick: () => {
                                            toast(
                                                formatMessage(
                                                    'songWriterManager.compsMoved',
                                                    {
                                                        number: selectedNonControlledRows.length,
                                                        controlledStatus:
                                                            'Controlled',
                                                    }
                                                )
                                            );
                                        },
                                        tooltip: formatMessage(
                                            'songWriterManager.moveSelectedComps',
                                            { controlledStatus: 'Controlled' }
                                        ),
                                    },
                                }}
                                columnDefs={[
                                    {
                                        name: 'title',
                                        title: `${formatMessage('songWriterManager.compositions')}`,
                                        sortable: true,
                                        sortKey: 'title',
                                        Cell: ({ data }) => {
                                            return (
                                                <div>
                                                    <div>{data.title}</div>
                                                    <div id="publisher">
                                                        {data.publisherName ??
                                                            ''}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                            />
                        </Section.Body>
                    </Section>
                </div>
            </div>
        </div>
    );
};

export default AssociatedCompositions;
