import React, { useCallback, useState } from 'react';
import { GlyphButton, Modal } from '@theorchard/suite-components';
import { formatMessage, useIdentity } from '@theorchard/suite-frontend';
import { FEATURE_FLAGS } from 'src/constants';
import { type SongWriter } from 'src/types';

interface Props {
    songWriter: SongWriter;
    onSongWriterEdit: (songWriter: SongWriter) => void;
    onSongWriterDelete: (songWriter: SongWriter) => void;
}

const NewSongwriterActions: React.FC<Props> = ({
    songWriter,
    onSongWriterEdit,
    onSongWriterDelete,
}) => {
    const identity = useIdentity();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const onDelete = useCallback(() => {
        onSongWriterDelete(songWriter);
        setDeleteModalOpen(false);
    }, [songWriter, onSongWriterDelete]);

    return (
        <div className="GridTable">
            <div className="table-extension-actions GridTable-actions-cell-content cell-table-new-actions ">
                <GlyphButton
                    name="edit"
                    size={'lg'}
                    className="TableActionButton new-action-btn action-edit suite-glyph-button"
                    onClick={() => onSongWriterEdit(songWriter)}
                ></GlyphButton>
                {identity.features[FEATURE_FLAGS.ALLOW_DELETE_SONG_WRITER] && (
                    <>
                        <GlyphButton
                            name="trash"
                            size={'lg'}
                            className="TableActionButton new-action-btn action-delete "
                            onClick={() => setDeleteModalOpen(true)}
                            disabled={songWriter.compositions.length > 0}
                        ></GlyphButton>
                        <Modal
                            isOpen={deleteModalOpen}
                            onRequestClose={() => setDeleteModalOpen(false)}
                            title={formatMessage(
                                'songWriters.deleteModalHeader'
                            )}
                            cancelTitle={formatMessage(
                                'songWriters.deleteCancellation'
                            )}
                            confirmTitle={formatMessage(
                                'songWriters.deleteConfirmation'
                            )}
                            onConfirm={onDelete}
                        >
                            {formatMessage('songWriters.deleteWarning')}
                        </Modal>
                    </>
                )}
            </div>
        </div>
    );
};

export default NewSongwriterActions;
