import React, { useState, useEffect, useCallback } from 'react';
import {
    Field,
    Form,
    NumberInput,
    Dropdown,
    Alert,
    Sidecar,
    Button,
    Control,
    Label,
} from '@theorchard/suite-components';
import {
    useAppConfig,
    formatMessage,
    Segment,
} from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import PublisherAsyncSelect from 'src/components/publisherAsyncSelect';
import SongWriterAsyncSelect from 'src/components/songWriterAsyncSelect';
import { defaultSongWriterValues } from 'src/components/songWriterAsyncSelect/songWriterAsyncSelect';
import {
    CONTACT_EMAILS,
    IPI_LENGTH,
    PRO_LIST,
    SHOW_ALL_LABEL,
} from 'src/constants';
import { useCreateSongWriter, useUpdateSongWriter } from 'src/data/mutations';
import { useApplicationContext } from 'src/utils/applicationContext';
import { pkaError } from 'src/utils/error';
import { useLimitModifyAccessFF } from 'src/utils/features';
import { isValidIpi } from 'src/utils/validators';
import type { PublishingCreateSongWriterMutation } from 'src/data/mutations/createSongWriter/__generated__/createSongWriter';
import type { PublishingSongWriterPublishersInput as PublisherInput } from 'src/data/schemaTypes';
import type { Publisher, SongWriter } from 'src/types';

type BoolCheck = boolean | null;

interface Props {
    isOpen: boolean;
    onClose: (result?: PublishingCreateSongWriterMutation) => void;
    headerLabel: string;
    validated: boolean;
}

interface PublisherInputInter {
    controlled: boolean;
    ipi: string | null | undefined;
    excludedTerritories: string[];
    name: string | null | undefined;
    id: string | undefined;
    pro: string | null | undefined;
}

const RegisterNewSongWriter: React.FC<Props> = ({
    isOpen,
    onClose,
    headerLabel,
    validated,
}) => {
    const config = useAppConfig();
    const { label, labelAlt } = useApplicationContext();
    const selectedLabel =
        !label || label?.name === SHOW_ALL_LABEL ? labelAlt : label;
    const publishingAppContactEmail = CONTACT_EMAILS[config.brand].appContact;
    const defaultSongWriter = defaultSongWriterValues;
    const isLimitedModifyAccess = useLimitModifyAccessFF();

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [name, setName] = useState('');
    const [professionallyKnownAs, setProfessionallyKnownAs] = useState<
        string[]
    >([]);
    const [isControlled, setIsControlled] = useState<BoolCheck>(null);
    const [ipi, setIpi] = useState<string | null>(null);
    const [pro, setPro] = useState<string | null>(null);
    const [isRegisteredWithPro, setIsRegisteredWithPro] =
        useState<BoolCheck>(null);
    const [songWriterTouched, setSongWriterTouched] =
        useState<BoolCheck>(false);
    const [songWriter, setSongWriter] = useState<SongWriter>(defaultSongWriter);
    const [hasPublishingCo, setHasPublishingCo] = useState<BoolCheck>(null);
    const [publishingCoTouched, setPublishingCoTouched] =
        useState<BoolCheck>(false);
    const [publishingCompany, setPublishingCompany] = useState('');
    const [publishingCompanyId, setPublishingCompanyId] = useState('');
    const [publishingCompanyIpi, setPublishingCompanyIpi] = useState('');
    const [publishingCompanyPro, setPublishingCompanyPro] = useState('');
    const [songWriterValidationErrorKeys, setSongWriterValidationErrorKeys] =
        useState<string[] | null>(null);

    const songWriterCreated = songWriter.id && songWriter.id !== '';
    const nameIsCorrect = songWriterCreated || name.length > 0;
    const songWriterIpiIsInvalid = ipi && !isValidIpi(ipi);
    const songWriterIpiIsNotUnique = songWriterValidationErrorKeys?.includes(
        'songWriterIPICollision'
    );
    const publisherIpiIsInvalid =
        publishingCompanyIpi && !isValidIpi(publishingCompanyIpi);
    const publisherIpiIsNotUnique = songWriterValidationErrorKeys?.includes(
        'publisherIPICollision'
    );
    const songWriterAndPublisherIpiMatch =
        songWriterValidationErrorKeys?.includes(
            'songWriterAndPublisherCompanyIpiMatch'
        );
    const alwaysRequiredFields =
        nameIsCorrect && typeof isControlled === 'boolean';
    const songWriterValidations = ipi && pro;
    const publisherValidations =
        publishingCompany && publishingCompanyIpi && publishingCompanyPro;
    const professionallyKnownAsIsInvalid = professionallyKnownAs.some(pkaError);

    const saveEnabled =
        ((nameIsCorrect && isControlled === false) ||
            (alwaysRequiredFields &&
                isRegisteredWithPro === false &&
                !isControlled) ||
            (alwaysRequiredFields &&
                songWriterValidations &&
                hasPublishingCo === false) ||
            (alwaysRequiredFields &&
                songWriterValidations &&
                publisherValidations)) &&
        !isLimitedModifyAccess;
    const isControlledMixed =
        defaultSongWriter.agreements?.some(a => a.controlled) &&
        defaultSongWriter.agreements?.some(a => !a.controlled);
    const nameIsValid = /\w \w/.exec(name || songWriter.legalName);
    const nameIsInvalid = !nameIsValid;
    const category = formatMessage('songWriterManager.newSongWriter');

    const reset = () => {
        setHasSubmitted(false);
        setName('');
        setProfessionallyKnownAs([]);
        setIsControlled(null);
        setIpi('');
        setPro(null);
        setIsRegisteredWithPro(null);
        setSongWriterTouched(false);
        setSongWriter(defaultSongWriter);
        setHasPublishingCo(null);
        setPublishingCoTouched(false);
        setPublishingCompany('');
        setPublishingCompanyId('');
        setPublishingCompanyIpi('');
        setPublishingCompanyPro('');
        setSongWriterValidationErrorKeys(null);
    };

    const closeAndReset = () => {
        onClose();
        reset();
    };

    const onSubmitCB = (
        result: object | null,
        errors: string[] | undefined
    ) => {
        if (result) onClose(result as PublishingCreateSongWriterMutation);
        else if (errors) setSongWriterValidationErrorKeys(errors);
    };
    const createSongWriter = useCreateSongWriter(onSubmitCB);
    const updateSongWriter = useUpdateSongWriter(onSubmitCB);
    const onIpiChange = (value: string, setter: (v: string) => void) => {
        setSongWriterValidationErrorKeys(null);
        let formatted = value.slice(-IPI_LENGTH);
        if (value.length < IPI_LENGTH)
            formatted = `00000000000${value}`.slice(-IPI_LENGTH);
        setter(formatted);
    };

    useEffect(() => {
        setSongWriter(defaultSongWriter);
        setName(defaultSongWriter.legalName);
        setProfessionallyKnownAs(defaultSongWriter.professionallyKnownAs || []);
        setIpi(defaultSongWriter.ipi || '');
        setPro(defaultSongWriter.pro || '');

        if (defaultSongWriter.agreements?.length && !isControlledMixed)
            setIsControlled(
                defaultSongWriter.agreements.some(a => a.controlled)
            );
    }, [defaultSongWriter, songWriter, isControlledMixed]);

    const onPublisherSelect = useCallback(
        (publisher: Publisher | undefined) => {
            setPublishingCoTouched(true);
            if (!publisher?.id) {
                setPublishingCompany('');
                setPublishingCompanyId('');
                setPublishingCompanyIpi('');
                setPublishingCompanyPro('');
            } else if (publisher) {
                setPublishingCompany(publisher.name || '');
                setPublishingCompanyId(publisher.id || '');
                setPublishingCompanyIpi(publisher.ipi || '');
                setPublishingCompanyPro(publisher.pro || '');
            }
        },
        []
    );

    const renderPublishingCompanyBlock = () => (
        <div>
            <PublisherAsyncSelect
                label={selectedLabel}
                onChange={onPublisherSelect}
            />

            {publishingCoTouched && !publishingCompanyId && (
                <>
                    <Field
                        controlId="publishingCompany"
                        labelText={formatMessage('generic.publishingCompany')}
                        isRequired={!!isControlled}
                    >
                        <Form.Control
                            type="text"
                            value={publishingCompany}
                            onChange={e => setPublishingCompany(e.target.value)}
                            placeholder={formatMessage(
                                'songWriterManager.publisherNamePlaceholder'
                            )}
                        />
                    </Field>
                    <Field
                        controlId="publishingCompanyPro"
                        labelText={formatMessage(
                            'generic.publishingCompanyPro'
                        )}
                        isRequired={!!isControlled}
                    >
                        <Dropdown
                            options={PRO_LIST.map((p: string) => ({
                                value: p,
                                label: p,
                            }))}
                            placeholder={formatMessage(
                                'songWriterManager.publisherProPlaceholder'
                            )}
                            selectedValue={publishingCompanyPro}
                            onChange={option => {
                                setPublishingCompanyPro(option?.value || '');
                            }}
                        />
                    </Field>
                    <Field
                        controlId="publishingCompanyIpi"
                        labelText={formatMessage(
                            'generic.publishingCompanyIpi'
                        )}
                        isRequired={!!isControlled}
                        errorMessage={
                            (hasSubmitted &&
                                ((publisherIpiIsInvalid &&
                                    formatMessage('errors.ipiNeeds9Chars')) ||
                                    (publisherIpiIsNotUnique &&
                                        formatMessage(
                                            'errors.publisherIPICollision'
                                        )) ||
                                    (songWriterAndPublisherIpiMatch &&
                                        formatMessage(
                                            'errors.songWriterAndPublisherCompanyIpiMatch'
                                        )))) ||
                            undefined
                        }
                    >
                        <NumberInput
                            value={publishingCompanyIpi}
                            onChange={(value: string) =>
                                onIpiChange(value, setPublishingCompanyIpi)
                            }
                            allowNegatives={false}
                            placeholder={formatMessage(
                                'songWriterManager.publisherIpiPlaceholder'
                            )}
                        />
                    </Field>
                </>
            )}
        </div>
    );
    const onSave = () => {
        if (!saveEnabled) return;

        setHasSubmitted(true);
        if (
            nameIsInvalid ||
            professionallyKnownAsIsInvalid ||
            songWriterIpiIsInvalid ||
            songWriterValidationErrorKeys ||
            publisherIpiIsInvalid ||
            professionallyKnownAsIsInvalid
        )
            return;

        const publishers: (PublisherInput | PublisherInputInter)[] =
            songWriter.agreements?.map(({ controlled, publisher }) => ({
                id: publisher?.id,
                name: publisher?.name ?? '',
                ipi: publisher?.ipi ?? '',
                pro: publisher?.pro ?? '',
                controlled,
                excludedTerritories: [],
            })) || [];

        const publisher: PublisherInput = {
            name: publishingCompany,
            ipi: publishingCompanyIpi,
            pro: publishingCompanyPro,
            controlled: !!isControlled,
            excludedTerritories: [],
        };

        if (publishingCompanyId) publisher.id = publishingCompanyId;

        publishers.push(publisher);

        const baseVariables = {
            id: songWriter.id,
            name: name || songWriter.legalName,
            professionallyKnownAs:
                professionallyKnownAs.length !== 0 ||
                songWriter.professionallyKnownAs.length !== 0
                    ? professionallyKnownAs || songWriter.professionallyKnownAs
                    : null,
            ipi: ipi || songWriter.ipi || '',
            pro: pro || songWriter.pro || '',
            label: { uuid: selectedLabel?.uuid || '' },
        };

        const variables = {
            ...baseVariables,
            publishers,
        };

        void Segment.trackEvent('Click - Created Songwriter', {
            category,
            songWriterId: baseVariables.id,
            songWriterLegalName: baseVariables.name,
            songWriterProfessionallyKnownAs:
                baseVariables.professionallyKnownAs,
            songWriterIpi: baseVariables.ipi,
            songWriterPro: baseVariables.pro,
        });

        if (songWriterCreated) void updateSongWriter({ variables });
        else void createSongWriter({ variables });
    };

    const onSongWriterChange = useCallback(
        (songwriter: SongWriter | undefined) => {
            setSongWriterTouched(!!songwriter);
            if (songwriter) {
                setSongWriter(songwriter);
                setProfessionallyKnownAs(
                    songWriter.professionallyKnownAs || []
                );
                setIpi(songwriter.ipi || '');
                setPro(songwriter.pro || '');
            }
        },
        [songWriter.professionallyKnownAs]
    );

    const addPKA = () => {
        setProfessionallyKnownAs([...professionallyKnownAs, '']);
    };
    const setPKA = (value: string, i: number) => {
        const newValues = [...professionallyKnownAs];
        newValues[i] = value;
        setProfessionallyKnownAs(newValues);
    };

    const removePKA = (i: number) => {
        const copy = [...professionallyKnownAs];
        copy.splice(i, 1);
        setProfessionallyKnownAs(copy);
    };

    return (
        <Sidecar
            isOpen={isOpen}
            onRequestClose={closeAndReset}
            className={cx(
                'SongWriterManager',
                saveEnabled ? '' : 'save-disabled'
            )}
            header={headerLabel}
            cancelTitle={formatMessage('generic.cancel')}
            confirmTitle={formatMessage('generic.save')}
            onConfirm={onSave}
        >
            <Form validated={validated}>
                <div>
                    <div className="NewSongWriter-new-songwriter-modal-subtitle mb-3">
                        {formatMessage(
                            'songWriterManager.registerNewSongWriterSubTitle'
                        )}
                    </div>

                    <div>
                        <div className="NewSongWriter-label">
                            {formatMessage('generic.labelAccount')}
                        </div>
                        <div className="NewSongWriter-value">
                            {selectedLabel?.name}
                        </div>
                    </div>

                    {selectedLabel?.uuid && (
                        <SongWriterAsyncSelect
                            label={selectedLabel}
                            onChange={onSongWriterChange}
                        />
                    )}
                    {songWriterTouched && (
                        <>
                            {!songWriterCreated && (
                                <Field
                                    controlId="legal-name"
                                    labelText={formatMessage(
                                        'songWriterManager.songWriterLegalName'
                                    )}
                                    isRequired
                                    errorMessage={
                                        (hasSubmitted &&
                                            !nameIsValid &&
                                            formatMessage(
                                                'errors.nameNeedsSpace'
                                            )) ||
                                        undefined
                                    }
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder={formatMessage(
                                            'songWriterManager.songWriterLegalNamePlaceholder'
                                        )}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </Field>
                            )}
                            {professionallyKnownAs.length !== 0 && (
                                <Label
                                    text={formatMessage(
                                        'songWriterManager.professionallyKnownAs'
                                    )}
                                    isRequired
                                />
                            )}
                            {professionallyKnownAs.map((pka, i) => (
                                <Control
                                    errorMessage={
                                        (hasSubmitted && pkaError(pka)) ||
                                        undefined
                                    }
                                    key={i}
                                    className="SongWriterManager-pka-field-control"
                                >
                                    <div className="SongWriterManager-pka-field">
                                        <Form.Control
                                            type="text"
                                            value={pka}
                                            placeholder={formatMessage(
                                                'songWriterManager.professionallyKnownAsPlaceholder'
                                            )}
                                            onChange={e =>
                                                setPKA(e.target.value, i)
                                            }
                                        />
                                        <div
                                            className="SongWriterManager-pka-field-x"
                                            onClick={() => removePKA(i)}
                                        >
                                            <GlyphIcon name="close" size={16} />
                                        </div>
                                    </div>
                                </Control>
                            ))}

                            <Button
                                variant="link"
                                className="icon-left"
                                onClick={addPKA}
                            >
                                <GlyphIcon name="plus" size={16} />
                                {formatMessage(
                                    'songWriterManager.addProfessionallyKnownAs'
                                )}
                            </Button>

                            <Field
                                controlId="isControlled"
                                labelText={formatMessage(
                                    'songWriterManager.isControlled'
                                )}
                                isRequired
                            >
                                <Form.Check
                                    checked={isControlled === true}
                                    onChange={() => setIsControlled(true)}
                                    name="isControlled"
                                    label={formatMessage('generic.yes')}
                                    type="radio"
                                    id="isControlled1"
                                />
                                <Form.Check
                                    checked={isControlled === false}
                                    onChange={() => setIsControlled(false)}
                                    name="isControlled"
                                    label={formatMessage('generic.no')}
                                    type="radio"
                                    id="isControlled2"
                                />
                            </Field>

                            {isControlled !== null && (
                                <Field
                                    controlId="isRegisteredWithPro"
                                    labelText={formatMessage(
                                        'songWriterManager.isSongWriterInPro'
                                    )}
                                    isRequired={isControlled}
                                >
                                    <Form.Check
                                        checked={isRegisteredWithPro === true}
                                        onChange={() =>
                                            setIsRegisteredWithPro(true)
                                        }
                                        name="isRegisteredWithPro"
                                        label={formatMessage('generic.yes')}
                                        type="radio"
                                        id="isRegisteredWithPro1"
                                    />
                                    <Form.Check
                                        checked={isRegisteredWithPro === false}
                                        onChange={() =>
                                            setIsRegisteredWithPro(false)
                                        }
                                        name="isRegisteredWithPro"
                                        label={formatMessage('generic.no')}
                                        type="radio"
                                        id="isRegisteredWithPro2"
                                    />
                                </Field>
                            )}

                            {isControlled && !isRegisteredWithPro && (
                                <Alert
                                    title={formatMessage(
                                        'songWriterManager.notRegisteredTitle'
                                    )}
                                    variant="warn"
                                    text={
                                        <>
                                            {formatMessage(
                                                'songWriterManager.notRegisteredSubTitle'
                                            )}
                                            <a
                                                href={`mailto:${publishingAppContactEmail}`}
                                            >
                                                {publishingAppContactEmail}
                                            </a>
                                        </>
                                    }
                                />
                            )}

                            {isRegisteredWithPro && (
                                <div>
                                    <Field
                                        controlId="pro"
                                        labelText={formatMessage(
                                            'songWriterManager.songwritersPro'
                                        )}
                                        isRequired={!!isControlled}
                                    >
                                        <Dropdown
                                            options={PRO_LIST.map(
                                                (p: string) => ({
                                                    value: p,
                                                    label: p,
                                                })
                                            )}
                                            placeholder={formatMessage(
                                                'songWriterManager.songwriterProPlaceholder'
                                            )}
                                            selectedValue={pro || undefined}
                                            onChange={option =>
                                                setPro(option?.value || null)
                                            }
                                        />
                                    </Field>

                                    <Field
                                        controlId="ipi"
                                        labelText={formatMessage(
                                            'songWriterManager.songwritersIpi'
                                        )}
                                        isRequired={!!isControlled}
                                        errorMessage={
                                            (hasSubmitted &&
                                                ((songWriterIpiIsInvalid &&
                                                    formatMessage(
                                                        'errors.ipiNeeds9Chars'
                                                    )) ||
                                                    (songWriterIpiIsNotUnique &&
                                                        formatMessage(
                                                            'errors.songWriterIPICollision'
                                                        )) ||
                                                    (songWriterAndPublisherIpiMatch &&
                                                        formatMessage(
                                                            'errors.songWriterAndPublisherCompanyIpiMatch'
                                                        )))) ||
                                            undefined
                                        }
                                    >
                                        <NumberInput
                                            value={ipi || ''}
                                            onChange={(value: string) =>
                                                onIpiChange(value, setIpi)
                                            }
                                            allowNegatives={false}
                                            placeholder={formatMessage(
                                                'songWriterManager.songwriterIpiPlaceholder'
                                            )}
                                        />
                                    </Field>

                                    <Field
                                        controlId="haveExistingPublishing"
                                        labelText={formatMessage(
                                            'songWriterManager.doesHaveExistingPublishingCo'
                                        )}
                                        isRequired={!!isControlled}
                                    >
                                        <Form.Check
                                            checked={hasPublishingCo === true}
                                            onChange={() =>
                                                setHasPublishingCo(true)
                                            }
                                            name="haveExistingPublishing"
                                            label={formatMessage('generic.yes')}
                                            type="radio"
                                            id="haveExistingPublishing1"
                                        />
                                        <Form.Check
                                            checked={hasPublishingCo === false}
                                            onChange={() =>
                                                setHasPublishingCo(false)
                                            }
                                            name="haveExistingPublishing"
                                            label={formatMessage('generic.no')}
                                            type="radio"
                                            id="haveExistingPublishing2"
                                        />
                                    </Field>

                                    {hasPublishingCo === true &&
                                        renderPublishingCompanyBlock()}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Form>
        </Sidecar>
    );
};
export default RegisterNewSongWriter;
