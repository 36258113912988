import React, { type FC, useCallback, useMemo } from 'react';
import { Select } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import {
    type Publisher,
    useGetPublishersQuery,
    usePublisherSearchQuery,
} from 'src/data/queries/publisherSearch/publisherSearch';

export const CLASSNAME = 'PublisherSearchDropdown';

export interface Props {
    labelUuid: string;
    publisherId?: string;
    onChange: (data?: Publisher) => void;
}

export interface DropdownOption<T> {
    label: string;
    value: string;
    data?: T;
}

const PublisherSearchDropdown: FC<Props> = props => {
    const { publisherId, onChange, labelUuid } = props;
    const { data: publisherData } = useGetPublishersQuery(labelUuid);
    const [executeSearch] = usePublisherSearchQuery();

    const createOption = (publisher: Publisher): DropdownOption<Publisher> => ({
        label: `${publisher.name ?? ''}`,
        value: `${publisher.id}`,
        data: publisher,
    });

    const extendedPublisherData = useMemo(() => {
        if (publisherData)
            return [
                { id: '0', name: 'No Publishing Company' },
                ...publisherData,
            ];
        return [{ id: '0', name: 'No Publishing Company' }];
    }, [publisherData]);

    const publisherOptions = useMemo<DropdownOption<Publisher>[]>(
        () => [...(extendedPublisherData ?? [])].map(createOption),
        [extendedPublisherData]
    );

    const onChangeHandler = useCallback(
        (newValue?: DropdownOption<Publisher>) => {
            onChange(newValue?.data);
        },
        [onChange]
    );

    const onLoadOptionsHandler = async (searchParam?: string) => {
        let searchPublisherData: Publisher[] = [];
        if (searchParam) {
            searchPublisherData =
                (await executeSearch({
                    variables: {
                        name: searchParam,
                        label: { uuid: labelUuid },
                    },
                })) || [];

            return { data: searchPublisherData?.map(createOption) ?? [] };
        }
        return {};
    };

    return (
        <Select
            name="publisherSearchDropdown"
            className={CLASSNAME}
            options={publisherOptions}
            onLoadOptions={onLoadOptionsHandler}
            onChange={onChangeHandler}
            placeholder={formatMessage('generic.publishingCompanySelect')}
            testId={'publisherSearchDropdown'}
            selectedValue={publisherId}
            menuWidth="100%"
        />
    );
};

export default PublisherSearchDropdown;
