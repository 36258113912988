import { useLazyQuery, useQuery } from '@apollo/client';
import { flattenError } from '@theorchard/suite-frontend';
import publisherSearchQuery from './publisherSearch.gql';
import type {
    PublisherSearchQuery,
    PublisherSearchQueryVariables,
} from './__generated__/publisherSearch';
import type { LazyQueryHookOptions } from '@apollo/client';

export interface Publisher {
    id: string;
    name?: string | null;
}

type PublisherSearchResult = [
    (
        variables: LazyQueryHookOptions<
            PublisherSearchQuery,
            PublisherSearchQueryVariables
        >
    ) => Promise<Publisher[] | undefined>,
    { loading: boolean; error?: Error; data?: Publisher[] },
];

export const usePublisherSearchQuery: () => PublisherSearchResult = () => {
    const [query, { data, loading, error }] = useLazyQuery<
        PublisherSearchQuery,
        PublisherSearchQueryVariables
    >(publisherSearchQuery);

    const queryHandler = async (
        options: LazyQueryHookOptions<
            PublisherSearchQuery,
            PublisherSearchQueryVariables
        >
    ) =>
        await query(options).then(
            result => result.data && result.data.publishingPublishers.publishers
        );

    return [
        queryHandler,
        {
            loading,
            error: error && flattenError(error),
            data: data && data.publishingPublishers.publishers,
        },
    ];
};

export const useGetPublishersQuery = (labelUuid: string) => {
    const { data, loading, error } = useQuery<PublisherSearchQuery>(
        publisherSearchQuery,
        { variables: { label: { uuid: labelUuid } }, skip: !labelUuid }
    );

    return {
        loading,
        data: data && data.publishingPublishers.publishers,
        error: error && flattenError(error),
    };
};
