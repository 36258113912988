import { useLazyQuery } from '@apollo/client';
import { PublishingSongWritersSimpleDocument } from './__generated__/songWritersSearchSimple';
import type {
    PublishingSongWritersSimpleQuery,
    PublishingSongWritersSimpleQueryVariables,
} from './__generated__/songWritersSearchSimple';
import type { Label } from 'src/types';

export type SimpleSongWriter =
    PublishingSongWritersSimpleQuery['publishingSongWriters']['songWriters'][0];

export const useLazyPublishingSongWritersSimpleSearch = (label: Label | null) =>
    useLazyQuery<
        PublishingSongWritersSimpleQuery,
        PublishingSongWritersSimpleQueryVariables
    >(PublishingSongWritersSimpleDocument, {
        variables: {
            filter: {
                label: label?.uuid ? { uuid: label.uuid } : null,
            },
        },
        fetchPolicy: 'cache-first',
    });
