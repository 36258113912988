import { type WriterAgreement } from 'src/types';

export interface AssociatedComposition {
    id: string;
    title: string;
    publisherName: string | null;
    publisherId: string | null;
    agreementId: string;
}

export const formatAssociatedComps = (
    agreements: WriterAgreement[]
): AssociatedComposition[] =>
    agreements
        .map(a => {
            if (!a.compositions.length) return null;
            return a.compositions.map(c => ({
                id: c.id,
                title: c.title,
                publisherName: a.publisher?.name ?? null,
                publisherId: a.publisher?.id ?? null,
                agreementId: a.id,
            }));
        })
        .filter(c => c !== null)
        .flatMap(c => c)
        .sort((a, b) =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        );
