import React, { useEffect, useState, type FC } from 'react';
import {
    FullscreenModal,
    useFullscreenModal,
} from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { useHistory, useParams } from 'react-router-dom';
import { useUpdateSongWriterMetaData } from 'src/data/mutations';
import { useSongwriterByIdQuery } from 'src/data/queries/songwriterById/songwriterById';
import { type UpdatePublishingSongWriterMetaDataInput } from 'src/data/schemaTypes';
import AssociatedComopositions from './components/associatedCompositions';
import SongWriterModalBasics from './components/modalBasics';
import SongWriterModalHeader from './components/modalHeader';
import type { Songwriter } from 'src/types';

export const CLASSNAME = 'SongWriterEditModal';

const SongWriterEditModal: FC<object> = () => {
    const history = useHistory<{ from?: string; notification?: string }>();
    const [songwriter, setSongwriter] = useState<Songwriter | undefined>(
        history.location.state as unknown as Songwriter | undefined
    );
    const [updateSongWriter, setUpdateSongWriter] =
        useState<UpdatePublishingSongWriterMetaDataInput>({
            id: songwriter?.id ?? '',
            label: { uuid: songwriter?.vendor.uuid ?? '' },
            name: songwriter?.legalName ?? '',
            ipi: songwriter?.ipi ?? '',
            pro: songwriter?.pro ? songwriter.pro : '',
            professionallyKnownAs: songwriter?.professionallyKnownAs.length
                ? songwriter.professionallyKnownAs
                : null,
        });
    const [baseModalErrors, setBaseModalErrors] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [songWriterValidationErrorKeys, setSongWriterValidationErrorKeys] =
        useState<string[]>();

    const { id } = useParams<{ id: string }>();

    const { data } = useSongwriterByIdQuery({ id });
    useEffect(() => {
        if (!songwriter && data) {
            setSongwriter(data);
            setUpdateSongWriter({
                id: data.id,
                label: { uuid: data.vendor.uuid },
                name: data.legalName,
                ipi: data.ipi ?? '',
                pro: data.pro ? data.pro : '',
                professionallyKnownAs: data.professionallyKnownAs ?? null,
            });
        }
    }, [songwriter, data]);

    const { close } = useFullscreenModal();
    const closeModalCallback = (notification?: string) => {
        const fromPath = history.location.state?.from;
        close(() =>
            history.push({
                pathname: fromPath ? fromPath : '/song-writers/1',
                state: { notification },
            })
        );
    };

    const onSubmitCB = (
        result: object | null,
        errors: string[] | undefined
    ) => {
        if (result)
            closeModalCallback(
                formatMessage('songWriters.songwriterChangesSaved')
            );
        else if (errors) setSongWriterValidationErrorKeys(errors);
    };

    const updateSongWriterMetaData = useUpdateSongWriterMetaData(onSubmitCB);

    const controlledAgreements =
        songwriter?.agreements.filter(a => a.controlled).flatMap(a => a) ?? [];
    const nonControlledAgreements =
        songwriter?.agreements.filter(a => !a.controlled).flatMap(a => a) ?? [];

    const onSave = () => {
        setHasSubmitted(true);
        if (baseModalErrors) return;
        void updateSongWriterMetaData({ variables: updateSongWriter });
    };

    return (
        <FullscreenModal className={CLASSNAME}>
            {songwriter && (
                <>
                    <SongWriterModalHeader
                        songwriterData={songwriter}
                        saveModalCallback={onSave}
                        closeModalCallback={closeModalCallback}
                        controlledAgreements={controlledAgreements}
                        nonControlledAgreements={nonControlledAgreements}
                    />
                    <FullscreenModal.Body>
                        <SongWriterModalBasics
                            songwriter={songwriter}
                            updateSongWriter={updateSongWriter}
                            setUpdateSongWriter={setUpdateSongWriter}
                            setBaseModalErrors={setBaseModalErrors}
                            hasSubmitted={hasSubmitted}
                            songWriterValidationErrorKeys={
                                songWriterValidationErrorKeys
                            }
                            setSongWriterValidationErrorKeys={
                                setSongWriterValidationErrorKeys
                            }
                        />
                        <AssociatedComopositions
                            controlledAgreements={controlledAgreements}
                            nonControlledAgreements={nonControlledAgreements}
                            labelUuid={songwriter.vendor.uuid}
                        />
                    </FullscreenModal.Body>
                </>
            )}
        </FullscreenModal>
    );
};

export default SongWriterEditModal;
