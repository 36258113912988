import React from 'react';
import { initApplication, Segment } from '@theorchard/suite-frontend';
import { Redirect } from 'react-router-dom';
import { FEATURE_FLAGS } from 'src/constants';
import PublishersLisPage from 'src/pages/publishersListPage';
import ApplicationProviderPlugin from 'src/utils/applicationContext';
import SelectExistingSong from './components/selectExistingSong';
import NewSong from './pages/newSongPage';
import SongListPage from './pages/songListPage';
import SongWriterEditModal from './pages/songWriterEditModal';
import SongWriterListPage from './pages/songWriterListPage';
import {
    songsDraftsUrl,
    songsSubmittedUrl,
    newSongUrl,
    publishersListUrl,
} from './utils/urls';

initApplication({
    profileType: 'PublishingProfile',
    featureFlagFilter: 'publishing_',
    title: 'Publishing',
    mainNav: {
        onClick: link => {
            void Segment.trackEvent('Click - Left Nav', {
                category: 'Left Nav',
                label: link.path,
            });
        },
        sections: [
            {
                id: 'main',
                items: [
                    {
                        term: 'generic.songs',
                        path: songsSubmittedUrl(1),
                        match: /^\/songs?\//,
                    },
                    {
                        term: 'generic.songWriters',
                        path: '/song-writers/1',
                        match: /^\/song-writers?\//,
                    },
                    {
                        term: 'generic.publishers',
                        path: publishersListUrl(1, {}),
                        match: /^\/publishers?\//,
                        featureFlags: [
                            FEATURE_FLAGS.ALLOW_DISPLAY_PUBLISHERS_LIST,
                        ],
                    },
                ],
            },
        ],
    },
    routes: [
        { path: '/', page: () => <Redirect to={songsSubmittedUrl(1)} /> },
        { path: '/songs/:tab', page: SongListPage },
        { path: newSongUrl(), page: SelectExistingSong },
        { path: '/song/:id', page: NewSong },
        { path: '/song/new/form', page: NewSong },
        { path: '/song-writers/:page', page: SongWriterListPage },
        {
            path: '/publishers/:page',
            page: PublishersLisPage,
            featureFlags: [FEATURE_FLAGS.ALLOW_DISPLAY_PUBLISHERS_LIST],
        },
        {
            path: '/songs/drafts/:page',
            page: () => <Redirect to={songsDraftsUrl(1)} />,
        }, // Deprecated format
        {
            path: '/songs/submitted/:page',
            page: () => <Redirect to={songsSubmittedUrl(1)} />,
        }, // Deprecated format
        {
            path: '/song-writer/:id',
            page: SongWriterEditModal,
            featureFlags: [FEATURE_FLAGS.USE_NEW_SONGWRITER_ACTIONS],
        },
    ],
    config: {
        auth0StoreTokenInClient: true,
    },
    plugins: [ApplicationProviderPlugin],
});
