import { useQuery } from '@apollo/client';
import { flattenError } from '@theorchard/suite-frontend';
import { type SongWriter } from 'src/types';
import songwriterByIdQuery from './songwriterById.gql';
import type {
    SongwriterByIdQueryVariables as Variables,
    SongwriterByIdQuery as Query,
} from './__generated__/songwriterById';

interface SongwriterByIdQueryResult {
    data: SongWriter | undefined;
    loading: boolean;
    error?: Error;
}

export const useSongwriterByIdQuery = (
    variables: Variables
): SongwriterByIdQueryResult => {
    const { data, loading, error } = useQuery<Query, Variables>(
        songwriterByIdQuery,
        {
            skip: !variables.id,
            variables,
            fetchPolicy: 'cache-first',
        }
    );

    return {
        loading,
        data: data && data.publishingSongWriter,
        error: error && flattenError(error),
    };
};
